import { DOCUMENT } from "@angular/common";
import { Component, Inject, Renderer2 } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { SidenavService } from "./layout/sidenav/sidenav.service";
import { ThemeService } from "../@fury/services/theme.service";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { Platform } from "@angular/cdk/platform";
import { SplashScreenService } from "../@fury/services/splash-screen.service";
import { RichiesteService } from "./services/richieste.service";
import { SharedDataService } from "./services/shared-data.service";
import { interval, Subscription } from "rxjs";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "fury-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  subscription: Subscription;

  constructor(
    private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    private splashScreenService: SplashScreenService,
    private authService: AuthService,
    private richiesteService: RichiesteService,
    private sharedDataService: SharedDataService
  ) {
    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.themeService.setStyle(queryParamMap.get("style"))
      );

    this.iconRegistry.setDefaultFontSetClass("material-icons-outlined");
    this.themeService.theme$.subscribe((theme) => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.sharedDataService.currentData.subscribe((currentData) =>
      this.updateRequestBadge()
    );

    this.updateRequestBadge();

    //const source = interval(2 * 60 * 1000);
    const source = interval(5 * 10 * 1000);
    this.subscription = source.subscribe((val) => {
      this.updateRequestBadge();
    });

    this.sidenavService.addItems([
      {
        name: "Dashboard",
        position: 5,
        type: "subheading",
        customClass: "first-subheading",
      },
      {
        name: "Dashboard",
        routeOrFunction: "/dashboard",
        icon: "dashboard",
        position: 10,
        pathMatchExact: true,
      },
      {
        name: "Shop",
        position: 15,
        type: "subheading",
        customClass: "first-subheading",
      },
      {
        name: "Ordini",
        icon: "shopping_bag",
        position: 20,
        subItems: [
          {
            name: "Lista Ordini",
            routeOrFunction: "/orders/list",
            icon: "format_list_bulleted",
            position: 15,
          },
          {
            name: "Statistiche Ordini",
            routeOrFunction: "/orders/statistics",
            icon: "bar_chart",
            position: 15,
          },
        ],
      },
      {
        name: "Consegne",
        icon: "local_shipping",
        position: 25,
        subItems: [
          {
            name: "Calendario",
            routeOrFunction: "/shipping/calendar",
            icon: "date_range",
            position: 10,
          },
          {
            name: "Lista Consegne",
            routeOrFunction: "/shipping/list",
            icon: "format_list_bulleted",
            position: 15,
          },
        ],
      },
      {
        name: "Anagrafiche",
        position: 30,
        type: "subheading",
        customClass: "first-subheading",
      },
      {
        name: "Clienti",
        icon: "supervisor_account",
        position: 35,
        subItems: [
          {
            name: "Lista Clienti",
            routeOrFunction: "/customer/list",
            icon: "format_list_bulleted",
            position: 11,
          },
        ],
      },
      {
        name: "Prodotti",
        icon: "storefront",
        position: 40,
        subItems: [
          {
            name: "Lista Prodotti",
            routeOrFunction: "/product/list",
            icon: "format_list_bulleted",
            position: 10,
          },
          {
            name: "Categorie",
            routeOrFunction: "/product/categorie",
            icon: "category",
            position: 15,
          },
        ],
      },
      {
        name: "Aree",
        routeOrFunction: "/area",
        icon: "blur_circular",
        position: 45,
      },
      {
        name: "Programma Fedeltà",
        position: 45,
        type: "subheading",
        customClass: "first-subheading",
      },
      /*{
        name: "Configura Fasce",
        routeOrFunction: "/programma-fedelta/configura-fasce",
        icon: "settings_input_component",
        position: 46,
      },*/
      {
        name: "Prodotti Regalo",
        routeOrFunction: "/programma-fedelta/configura-prodotti",
        icon: "card_giftcard",
        position: 46,
      },
      {
        name: "Statistiche",
        position: 48,
        type: "subheading",
        customClass: "first-subheading",
      },
      {
        name: "Statistiche",
        routeOrFunction: "/statistiche",
        icon: "query_stats",
        position: 49,
        badge: "0",
        badgeColor: "#c40808",
        badge2: "0",
        badgeColor2: "#8cad00",
      },
      {
        name: "Supporto",
        position: 50,
        type: "subheading",
        customClass: "first-subheading",
      },
      {
        name: "Richieste Modifiche",
        routeOrFunction: "/richieste",
        icon: "chat",
        position: 55,
        badge: "0",
        badgeColor: "#c40808",
        badge2: "0",
        badgeColor2: "#8cad00",
      },
      {
        name: "Configurazione",
        position: 60,
        type: "subheading",
        customClass: "first-subheading",
      },
      {
        name: "Configurazione",
        routeOrFunction: "/configurazione",
        icon: "settings",
        position: 65,
      } ,
    ]);
  }
  updateRequestBadge() {
    if (this.authService.isAuth())
      this.richiesteService.getCount().subscribe((result) => {
        this.sidenavService.items.forEach((element) => {
          if (element.name == "Richieste Modifiche") {
            element.badge = result.daLeggere.toString();
            element.badge2 = result.daGestire.toString();
          }
        });
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
