import { Injectable } from '@angular/core';
import {HttpInterceptor} from '@angular/common/http'
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService) { }
  
  noAuthURLs = [
    environment.baseUrl +environment.authAPI,
    environment.baseUrl+"clienti/isPasswordResetTokenValid",
    environment.baseUrl+"clienti/setNewPassword"];

  intercept(request, next){ 
    let url: string = request.url;
    if(!this.noAuthURLs.some(x=>x==url)){
      let tokenizeReq = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.auth.getUser().token
        }
      })
      return next.handle(tokenizeReq);
    } else{
      return next.handle(request);
    }
    
  }
}
